import "./App.css";
import "./bootstrap.min.css";
import "./LineIcons.2.0.css";
import React from "react";
import ReactGA from "react-ga4";
import heroImg from "./assets/images/phone2.png";
import logoWhite from "./assets/images/logo_white.png";

function App() {
  ReactGA.initialize("G-PNVZ1XLESK");
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Landing",
  });
  return (
    <>
      <header className="header navbar-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="nav-inner">
                <nav className="navbar navbar-expand-lg">
                  <a className="navbar-brand" href="index.html">
                    <img src={logoWhite} alt="Logo" />
                  </a>
                  <button
                    className="navbar-toggler mobile-menu-btn"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                  >
                    <ul id="nav" className="navbar-nav ms-auto">
                      {/* <li className="nav-item">
                        <a
                          href="#home"
                          className="page-scroll active"
                          aria-label="Toggle navigation"
                        >
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#features"
                          className="page-scroll"
                          aria-label="Toggle navigation"
                        >
                          Features
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="javascript:void(0)"
                          aria-label="Toggle navigation"
                        >
                          Overview
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="#pricing"
                          className="page-scroll"
                          aria-label="Toggle navigation"
                        >
                          Pricing
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          href="javascript:void(0)"
                          aria-label="Toggle navigation"
                        >
                          Team
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="dd-menu collapsed"
                          href="javascript:void(0)"
                          data-bs-toggle="collapse"
                          data-bs-target="#submenu-1-4"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          Blog
                        </a>
                        <ul className="sub-menu collapse" id="submenu-1-4">
                          <li className="nav-item">
                            <a href="javascript:void(0)">Blog Grid Sidebar</a>
                          </li>
                          <li className="nav-item">
                            <a href="javascript:void(0)">Blog Single</a>
                          </li>
                          <li className="nav-item">
                            <a href="javascript:void(0)">Blog Single Sibebar</a>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item">
                        <a
                          href="javascript:void(0)"
                          aria-label="Toggle navigation"
                        >
                          Contact
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="button add-list-button">
                    <a href="https://app.shakeasipper.com" className="btn">
                      Try it now
                    </a>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section id="home" className="hero-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 col-12">
              <div className="hero-content">
                <h1 className="wow fadeInLeft" data-wow-delay=".4s">
                  Shake Up
                  <br />
                  Your Spirits!
                </h1>
                <p className="wow fadeInLeft" data-wow-delay=".6s">
                  Shake a Sipper invites you to a realm where cocktails are more
                  than drinks... they’re an experience. <br />
                  Curate your personal cocktail library, get inspired by the
                  community’s creations, and elevate your sipping sessions.
                </p>
                <div className="button wow fadeInLeft" data-wow-delay=".8s">
                  <a href="https://app.shakeasipper.com" className="btn">
                    <i className="lni lni-chrome"></i> Web Version
                  </a>
                  {/* <a href="javascript:void(0)" className="btn">
                    <i className="lni lni-apple"></i> App Store
                  </a> */}
                  <a href="https://play.google.com/store/apps/details?id=com.alejandrorebecchi.shakeasipper" className="btn btn-alt">
                    <i className="lni lni-play-store"></i> Google Play
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-12">
              <div className="hero-image wow fadeInRight" data-wow-delay=".4s">
                <img src={heroImg} alt="#" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="features" className="features section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="wow zoomIn" data-wow-delay=".2s">
                  Features
                </h3>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  Mix, Match, and Share!
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  At Shake a Sipper, we believe every cocktail tells a story.
                  Our features are designed to help you write yours. Explore a
                  world of mixology, keep track of your beloved concoctions, and
                  share your signature drinks with a community that cheers for
                  creativity.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".2s">
                <i className="lni lni-search-alt"></i>
                <h3>Discover & Search: Your Cocktail Compass</h3>
                <p>
                  Lost in the sea of spirits? Let our Discover & Search be your
                  guide. Navigate through an extensive collection of cocktail
                  recipes, or find the perfect mix by searching with your
                  favorite ingredients and categories. It's your personal
                  bartender in the palm of your hand.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".4s">
                <i className="lni lni-heart"></i>
                <h3>Favorites: Your Personalized Cocktail Menu</h3>
                <p>
                  Crafting your cocktail menu has never been easier. Save your
                  top mixes in Favorites and revisit them anytime. Whether it's
                  a refreshing summer drink or a cozy winter warmer, your
                  curated list is ready to serve up the perfect sip, every time.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="single-feature wow fadeInUp" data-wow-delay=".6s">
                <i className="lni lni-star"></i>
                <h3>Share: Stir & Serve Your Creations</h3>
                <p>
                  Got a recipe that's too good to keep to yourself? Share it
                  with the Shake a Sipper community. Inspire and be inspired as
                  you exchange recipes, tips, and tales from behind the bar.
                  It's a space where every mixologist, amateur or pro, can
                  shine.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="our-achievement section"
        style={{
          backgroundImage:
            "url(" + require("./assets/images/friends.jpeg") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-md-12 col-12">
              <div className="title">
                <h2>Keep shaking all day long!</h2>
                <p>
                  Our ever growing library of cocktails will keep you on your
                  toes. <br />
                  We have a recipe for every mood, every season, and every
                  occasion.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-12">
                  <div
                    className="single-achievement wow fadeInUp"
                    data-wow-delay=".2s"
                  >
                    <h3 className="counter">
                      <span id="secondo1" className="countup" cup-end="100">
                        400
                      </span>
                      +
                    </h3>
                    <p>recipes</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div
                    className="single-achievement wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <h3 className="counter">
                      <span id="secondo2" className="countup" cup-end="120">
                        300
                      </span>
                      +
                    </h3>
                    <p>Ingredients</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12">
                  <div
                    className="single-achievement wow fadeInUp"
                    data-wow-delay=".6s"
                  >
                    <h3 className="counter">
                      <span id="secondo3" className="countup" cup-end="125">
                        125
                      </span>
                      k+
                    </h3>
                    <p>Happy Users</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* 

      <section id="pricing" className="pricing-table section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3 className="wow zoomIn" data-wow-delay=".2s">
                  pricing
                </h3>
                <h2 className="wow fadeInUp" data-wow-delay=".4s">
                  Pricing Plan
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-table wow fadeInUp" data-wow-delay=".2s">
                <div className="table-head">
                  <h4 className="title">Hobby</h4>
                  <p>All the basics for starting a new business</p>
                  <div className="price">
                    <h2 className="amount">
                      $12<span className="duration">/mo</span>
                    </h2>
                  </div>
                  <div className="button">
                    <a href="javascript:void(0)" className="btn">
                      Buy Hobby
                    </a>
                  </div>
                </div>

                <div className="table-content">
                  <h4 className="middle-title">What's Included</h4>
                  <ul className="table-list">
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Cras justo
                      odio.
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Dapibus ac
                      facilisis in.
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Morbi leo
                      risus.
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Potenti
                      felis, in cras ligula.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-table wow fadeInUp" data-wow-delay=".4s">
                <div className="table-head">
                  <h4 className="title">Freelancer</h4>
                  <p>All the basics for starting a new business</p>
                  <div className="price">
                    <h2 className="amount">
                      $24<span className="duration">/mo</span>
                    </h2>
                  </div>
                  <div className="button">
                    <a href="javascript:void(0)" className="btn">
                      Buy Freelancer
                    </a>
                  </div>
                </div>

                <div className="table-content">
                  <h4 className="middle-title">What's Included</h4>

                  <ul className="table-list">
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Cras justo
                      odio.
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Dapibus ac
                      facilisis in.
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Morbi leo
                      risus.
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Potenti
                      felis, in cras ligula.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-table wow fadeInUp" data-wow-delay=".6s">
                <div className="table-head">
                  <h4 className="title">Startup</h4>
                  <p>All the basics for starting a new business</p>
                  <div className="price">
                    <h2 className="amount">
                      $32<span className="duration">/mo</span>
                    </h2>
                  </div>
                  <div className="button">
                    <a href="javascript:void(0)" className="btn">
                      Buy Startup
                    </a>
                  </div>
                </div>

                <div className="table-content">
                  <h4 className="middle-title">What's Included</h4>

                  <ul className="table-list">
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Cras justo
                      odio.
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Dapibus ac
                      facilisis in.
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Morbi leo
                      risus.
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Potenti
                      felis, in cras ligula.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-table wow fadeInUp" data-wow-delay=".8s">
                <div className="table-head">
                  <h4 className="title">Enterprise</h4>
                  <p>All the basics for starting a new business</p>
                  <div className="price">
                    <h2 className="amount">
                      $48<span className="duration">/mo</span>
                    </h2>
                  </div>
                  <div className="button">
                    <a href="javascript:void(0)" className="btn">
                      Buy Enterprise
                    </a>
                  </div>
                </div>

                <div className="table-content">
                  <h4 className="middle-title">What's Included</h4>
                  <ul className="table-list">
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Cras justo
                      odio.
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Dapibus ac
                      facilisis in.
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Morbi leo
                      risus.
                    </li>
                    <li>
                      <i className="lni lni-checkmark-circle"></i> Potenti
                      felis, in cras ligula.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section call-action">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-12 col-12">
              <div className="cta-content">
                <h2 className="wow fadeInUp" data-wow-delay=".2s">
                  You are using free Lite <br />
                  Version of Appvilla
                </h2>
                <p className="wow fadeInUp" data-wow-delay=".4s">
                  Please, purchase full version of the template to get all
                  pages, features and commercial license.
                </p>
                <div className="button wow fadeInUp" data-wow-delay=".6s">
                  <a href="javascript:void(0)" className="btn">
                    Purchase Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-12">
                <div className="single-footer f-about">
                  <div className="logo">
                    <a href="index.html">
                      <img src={logoWhite} alt="#" />
                    </a>
                  </div>
                  {/* <p>
                    Making the world a better place through constructing elegant
                    hierarchies.
                  </p> */}
                  {/* <ul className="social">
                    <li>
                      <a href="javascript:void(0)">
                        <i className="lni lni-facebook-filled"></i>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="lni lni-twitter-original"></i>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="lni lni-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="lni lni-linkedin-original"></i>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="lni lni-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="lni lni-pinterest"></i>
                      </a>
                    </li>
                  </ul>
                  <p className="copyright-text">
                    Designed by{" "}
                    <a
                      href="https://uideck.com/"
                      rel="nofollow noreferrer"
                      target="_blank"
                    >
                      UIdeck
                    </a>
                  </p> */}
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-12">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-footer f-link">
                      <h3>Support</h3>
                      <ul>
                        <li>
                          <a href="https://insigh.to/b/shake-a-sipper">
                            Request a feature
                          </a>
                          <li>
                            <a href="mailto:support@shakeasipper.com">
                              Contact Us
                            </a>
                          </li>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-footer f-link">
                      <h3>Solutions</h3>
                      <ul>
                        <li>
                          <a href="javascript:void(0)">Marketing</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Analytics</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Commerce</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Insights</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Promotion</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-footer f-link">
                      <h3>Company</h3>
                      <ul>
                        <li>
                          <a href="javascript:void(0)">About Us</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Our Blog</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Jobs</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Press</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className="single-footer f-link">
                      <h3>Legal</h3>
                      <ul>
                        <li>
                          <a href="javascript:void(0)">Terms & Conditions</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Catering Services</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Customer Relations</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Innovation</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
